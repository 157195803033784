<template>  
  <section>

    <div v-if="!loading">

        <button 
          v-on:click="loginSubmit"
          class="mt-2 btn btn-fill btn-block btn-primary"
        >
          {{ $t("LOGIN.SUBMIT") }}
        </button>
      
    </div>
    <div class="text-center" v-if="loading">

      <div v-if="hintMessage == 'outstandingTransaction'">
        
        <div v-if="!showOnThisDevice">
          <qrcode-vue
            v-if="autoStartToken != ''"
            :value="bankidUrl"
            :key="autoStartToken"
            size="200"
            level="H" class="qrcode mb-2"
          ></qrcode-vue>

           <InformationMessage
           class="mt-2 mb-2"
          type="information"
        >
         1. {{ $t("BANKID_SE.PROCESS.outstandingTransaction.step1") }}.<br />
          2. {{ $t("BANKID_SE.PROCESS.outstandingTransaction.step2") }}.<br />
          3. {{ $t("BANKID_SE.PROCESS.outstandingTransaction.step3") }}.<br />
          4. {{ $t("BANKID_SE.PROCESS.outstandingTransaction.step4") }}.<br />
           </InformationMessage>
           <a variant="primary" :href="bankidUrlDevice" target="_blank">{{ $t("SAME_DEVICE") }}</a>
         </div>
        <div class="mt-2 mb-2" v-if="showOnThisDevice">
          <a class="btn btn-fill btn-primary text-white" target="_blank" :href="bankidUrlDevice"> {{ $t("SAME_DEVICE") }}</a>
          <br />
          <br />
          <a href="#" @click.prevent="otherDevice"> {{ $t("OTHER_DEVICE") }}</a>
        </div>
      </div>

       <div v-if="hintMessage != 'outstandingTransaction'">
        <InformationMessage v-if="hintMessage != 'outstandingTransaction'" class="mt-2 mb-2" type="information">
          {{ statusMessage }}
        </InformationMessage>
      </div>

        <button @click.prevent="cancelSubmit" class="mt-2 btn btn-secondary">
        {{ $t("CANCEL") }}
      </button>
    </div>

  </section>
</template>
<script>
import mobileDetect from "mobile-device-detect";
export default {
  components: {  
    InformationMessage: () => import("@/components/Layout/InformationMessage"),
    QrcodeVue: () => import("qrcode.vue")
  },
  props: ["loginInfo"],
  data() {
    return {
      device: mobileDetect,
      loading: true,
      auth_uuid: '',
      qrCode: "",
      autoStartToken: "",
      statusMessage: '',
      timer: "",
      hintMessage: '',
      errorMessage: "",
      showOnThisDevice: false
    };
  },
  methods: {
    otherDevice: function() {
      this.showOnThisDevice = false;
    },
    reset: function() {
      this.autoStartToken = "";
      this.loading = false;
      clearTimeout(this.timer);
      this.showOnThisDevice = this.device.isMobile;
      this.auth_uuid = "";
      this.$emit('loading',false);
    },
    cancelSubmit: function() {
      let self = this;
      this.$http
        .get(self.user.hostname + "/authenticate/cancel/" + this.auth_uuid)
        .then(function() {
          self.reset();
        })
        .catch(function() {
          self.reset();
        });
    },
    loginSubmit: function() {
      this.loading = true;
      let self = this;
      self.$emit("loading",true);
      this.$http
        .post(self.user.hostname + "/authenticate", {
          email: this.user.email,
          auth_method: this.loginInfo.auth_method,
          auth_type: this.loginInfo.auth_type,
          client: "addin"
        })
        .then(function(result) {
          let redirect = false;
          if(result.data.redirect != undefined)
          {
            if(result.data.redirect == 1)
            {
              redirect = true;
            }
          }
          if(redirect)
          {
            self.$emit('startAuthentication',result.data.request_url);
          }else{            
            if(result.data.qrCode != undefined)
            {
              self.qrCode = result.data.qrCode;
            }
            self.statusMessage = '';
            self.auth_uuid = result.data.auth_uuid;
            self.autoStartToken = result.data.autoStartToken;
            self.$store.dispatch('logger/add','authenticated got autoStartToken');
            self.checkStatus();
          }
        })
        .catch(function(error) {
          self.reset();
          self.$store.dispatch('logger/add','authenticated error loginSubmit');
          if(error.toString() == "Error: fail_4_4") return;
          if(error.response != undefined)
          {
            self.$noty.error(self.$t(error.response.data));
          }
        });
    },
    checkStatus: function() {
      this.loading = true;
      let self = this;
      this.$http
        .get(self.user.hostname + "/authenticate/status/" + this.auth_uuid)
        .then(function(result) {
          if(result.status == 200)
          {
            self.verifyAuthentication();
            self.$store.dispatch('logger/add','authenticated checkStatus finished');
            clearTimeout(self.timer);
          }
          if(result.status == 202)
          {
            if(result.data.hintCode != undefined)
            {
              if(result.data.hintCode == "startFailed")
              {                
                self.reset();
                self.$noty.error(self.$t("BANKID_SE.ERROR.startFailed"));
              }else{
                self.hintMessage = result.data.hintCode;              
                self.qrCode = result.data.qrCode;
                self.statusMessage = self.$t("BANKID_SE.PROCESS." + result.data.hintCode);
                self.timer = setTimeout(function() {
                  self.checkStatus();
                }, 500);
              }
            }else{
              if(result.data == "startFailed")
              {                
                self.reset();
                self.$noty.error(self.$t("BANKID_SE.ERROR.startFailed"));
              }else{
                self.hintMessage = result.data;              
                self.statusMessage = self.$t("BANKID_SE.PROCESS." + result.data);
                self.timer = setTimeout(function() {
                  self.checkStatus();
                }, 500);
              }
            }
            self.$store.dispatch('logger/add','authenticated checkStatus');
          }
        })
        .catch(function(error) {
          self.reset();
          self.$store.dispatch('logger/add','authenticated error checkStatus');
          if(error.toString() == "Error: fail_4_4") return;
          self.$noty.error(self.$t(error.response.data));
        });
    },
    verifyAuthentication: function() {
      let self = this;
      this.$http
      .get(self.user.hostname +"/authenticate/result/" + this.auth_uuid)
      .then(function(result) {
        self.$emit('authenticated',result.data.token);
        self.$store.dispatch('logger/add','authenticated');
      })
      .catch(function(error) {
        self.reset();
        self.$store.dispatch('logger/add','authenticated error verifyAuthentication');
        if(error.toString() == "Error: fail_4_4") return;
        self.$noty.error(self.$t(error.response.data));
      });
    }
  },
  computed: {
    bankidUrlDevice() {
      if(this.device.isMobile)
      {
        return this.bankidUrl;
        //return "https://app.bankid.com/?autostarttoken=" + this.autoStartToken + "&redirect=null";
      }else{
        return this.bankidUrl;
      }
    },
    bankidUrl() {
      if(this.qrCode == "")
      {
        return "bankid:///?autostarttoken=" + this.autoStartToken + "&redirect=null";
      }else{
        return this.qrCode;
      }
    },
  },
  mounted: function() {
    this.loading = false;
    this.showOnThisDevice = this.device.isMobile;
  }
};
</script>
<style></style>
